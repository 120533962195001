const API_URL = window.location.origin;

export const api = {
  login: async (phoneNumber) => {
    try {
      const response = await fetch(`${API_URL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber }),
        credentials: "include",
      });
      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred during login. Please try again.",
      };
    }
  },

  verifyLoginCode: async (phoneNumber, loginCode) => {
    try {
      const response = await fetch(`${API_URL}/api/verifyLoginCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, loginCode }),
        credentials: "include",
      });

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: error.message || "An error occurred during verification.",
      };
    }
  },

  getLoggedIn: async () => {
    try {
      const response = await fetch(`${API_URL}/api/loggedIn`, {
        method: "GET",
        credentials: "include",
      });

      if (response.status === 401) {
        return { success: false, message: "User is not logged in." };
      }

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "Unable to fetch logged-in user.",
      };
    }
  },

  logout: async () => {
    try {
      const response = await fetch(`${API_URL}/api/logout`, {
        method: "POST",
        credentials: "include",
      });
      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred during logout. Please try again.",
      };
    }
  },

  getRole: async () => {
    try {
      const response = await fetch(`${API_URL}/api/getRole`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (error) {
      return { success: false, message: "Failed to fetch role." };
    }
  },

  getBottles: async () => {
    try {
      const response = await fetch(`${API_URL}/api/bottles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch bottles");
      }

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while fetching bottles.",
      };
    }
  },

  adminLogin: async (username, password) => {
    try {
      const response = await fetch(`${API_URL}/api/loginAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
        credentials: "include",
      });
      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred during admin login. Please try again.",
      };
    }
  },

  adminGetBottles: async (phoneNumber) => {
    try {
      const response = await fetch(
        `${API_URL}/adminAPI/bottles?phoneNumber=${phoneNumber}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        return {
          success: false,
          message: "Failed to fetch bottles",
        };
      }

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while fetching bottles for admin.",
      };
    }
  },

  createBottle: async (phoneNumber, bottleName, clubName, capacity) => {
    try {
      const response = await fetch(`${API_URL}/adminAPI/createBottle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, bottleName, clubName, capacity }),
        credentials: "include",
      });

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while creating a bottle.",
      };
    }
  },

  updateBottleCapacity: async (id, newCapacity) => {
    try {
      const response = await fetch(`${API_URL}/adminAPI/updateBottleCapacity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, newCapacity }),
        credentials: "include",
      });

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while updating bottle capacity.",
      };
    }
  },

  updateBottleExpiration: async (id, newExpiration) => {
    try {
      const response = await fetch(
        `${API_URL}/adminAPI/updateBottleExpiration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, newExpiration }),
          credentials: "include",
        }
      );

      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while updating bottle expiration.",
      };
    }
  },

  deleteBottle: async (id) => {
    try {
      const response = await fetch(`${API_URL}/adminAPI/deleteBottle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
        credentials: "include",
      });
      return response.json();
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while deleting the bottle.",
      };
    }
  },
};
