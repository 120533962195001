import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./guestHomePage.css";

function GuestHomePage() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="guestHome-container">
      <div className="guestHome-image">
        <div className="welcome-message">
          <h1>WELCOME TO</h1>
          <p className="logo">Bottles Custody Service</p>
        </div>
        <div className="welcome-bottom">
          <button className="main-login-button" onClick={handleLoginClick}>
            LOGIN
          </button>
          <div className="privacy-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestHomePage;
