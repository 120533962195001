import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./api/authContext";
import { UserProvider } from "./api/userContext";
import LoadingScreen from "./components/shared-content/loading/loading";
import "./App.css";

// Eager load components
import GuestHomePage from "./components/guest-content/guestHomePage";
import Login from "./components/guest-content/login";
import AdminLogin from "./components/guest-content/adminLogin";

// Lazy load components
const Header = lazy(() => import("./components/user-content/header"));
const Home = lazy(() => import("./components/user-content/home"));
const ClubContent = lazy(() => import("./components/user-content/clubContent"));

const AdminHeader = lazy(() => import("./components/admin-content/header"));
const AdminNav = lazy(() => import("./components/admin-content/nav"));
const AdminHome = lazy(() => import("./components/admin-content/home"));
const AdminSearch = lazy(() => import("./components/admin-content/search-users/searchUsers"));
const AdminCreateBottle = lazy(() => import("./components/admin-content/createBottle"));
const AdminClubContent = lazy(() =>
  import("./components/admin-content/clubContent")
);

function App() {
  const { currentUser } = useAuth();

  return (
    <Suspense fallback={<LoadingScreen />}>
      {!currentUser ? (
        <Routes>
          <Route path="/" element={<GuestHomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminLogin" element={<AdminLogin />} />
        </Routes>
      ) : (
        <div className="app-container">
          {currentUser?.role === "user" && (
            <>
              <Header />
              <div className="main-container">
                <Routes>
                  <Route path="/bottles" element={<Home />} />
                  <Route path="/" element={<Navigate to="/bottles" />} />
                  <Route path="/club/:clubId" element={<ClubContent />} />
                </Routes>
              </div>
            </>
          )}

          {currentUser?.role === "admin" && (
            <>
              <AdminHeader />
              <AdminNav />
              <div className="main-container">
                <Routes>
                  <Route path="/adm/bottles" element={<AdminHome />} />
                  <Route path="/adm/searchUsers" element={<AdminSearch />} />
                  <Route path="/" element={<Navigate to="/adm/searchUsers" />} />
                  <Route
                    path="/adm/create-bottle"
                    element={<AdminCreateBottle />}
                  />
                  <Route
                    path="/adm/club/:clubId"
                    element={<AdminClubContent />}
                  />
                </Routes>
              </div>
            </>
          )}
        </div>
      )}
    </Suspense>
  );
}

export default function AppWrapper() {
  return (
    <UserProvider>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </UserProvider>
  );
}
