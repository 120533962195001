import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/authContext";
import "./forms.css";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await auth.adminLogin(username, password);
      if (result.success) {
        const role = result.role;
        if (role === "admin") {
          navigate("/adm/searchUsers");
        } else {
          setErrorMessage("Unauthorized access. Admins only.");
        }
      } else {
        setErrorMessage(result.message || "Login failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred during login. Please try again.");
    }
  };

  return (
    <div className="guestHome-container">
      <div className="guestHome-image">
        <div className="login-container">
          <h2>ADMIN LOGIN</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleSubmit} className="main-button">
            LOGIN
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
